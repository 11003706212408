import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DynamicTableType, PAIRINGS_CONFIG, PAIRINGS_6, PAIRINGS_8, TournamentData, TournamentTable} from '../../data/tournaments';
import {Member, members} from '../../data/members';

interface ResultCount {
  wins: number;
  draws: number;
  losses: number;
  total: number;
}

interface MatchUp {
  round?: number;
  game?: number;
  inverse?: boolean;
}

@Component({
  selector: 'result-table',
  templateUrl: './result-table.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./result-table.component.scss']
})

export class ResultTableComponent implements OnInit {
  @HostBinding('class') class = 'resulttable';
  @Input() tournamentTable: TournamentTable;
  @Input() tournamentData: TournamentData;
  data: string;
  resultsTable: number[][][] = [];

  private getResult(runde: number, paarung: number): string {
    const res = this.tournamentData.results[runde][paarung];
    let result = '';
    switch (res) {
      case 'W': result = '1 - 0'; break;
      case 'B': result = '0 - 1'; break;
      case 'R': result = '½ - ½'; break;
      case '+': result = '+ - -'; break;
      case '-': result = '- - +'; break;
      default: result = '';
    }
    return result;
  }

  private getPoints(runde: number, paarung: number, black): number | undefined {
    const res = this.tournamentData.results[runde][paarung];
    let result = 0;
    if (res === 'x') { return undefined; }
    switch (res) {
      case 'W': result = 1; break;
      case 'B': result = 0; break;
      case 'R': result = 0.5; break;
      case '+': result = 1; break;
      case '-': result = 0; break;
      default: result = 0;
    }
    return black ? 1 - result :  result;
  }

  private pointsInRound(runde: number, member: number, pairings: number[][][], resultCount?): number {
    let pairingIndex = -1;
    const pairing = pairings[runde].find(
      (row, index) => {
        const isMember = row.includes(member);
        if (isMember) { pairingIndex = index; }
        return isMember;
      }
    );
    const black = pairing[1] === member;
    const points = this.getPoints(runde, pairingIndex, black);
    if (resultCount && points !== undefined) {
      switch (points) {
        case 1: resultCount.wins++; break;
        case 0.5: resultCount.draws++; break;
        case 0: resultCount.losses++; break;
      }
      resultCount.total++;
    }
    return points || 0;
  }

  private pointsAfterRound(runde: number, member: number, pairings: number[][][], resultCount?): number {
    if (runde < 0) { return 0; }
    return pairings.reduce(
      (acc, row, index) => {
        const points = index < runde ? this.pointsInRound(index, member, pairings, resultCount) : 0;
        return acc + points;
      }, 0
    );
  }

  private getMatchUp(playerA: number, playerB: number): MatchUp {
    const matchUp: MatchUp = {};
    this.resultsTable.find(
      (row, index) => {
        const matched = row.find(
          (row2, index2) => {
            const matches = row2.includes(playerA) && row2.includes(playerB);
            matchUp.inverse = row2[1] === playerA;
            matchUp.game = index2;
            matchUp.round = index;
            return matches;
          }
        );
        return matched;
      }
    );
    return matchUp;
  }

  private getSoBerForPlayer(player: number, pointsForMember: number[]): number {
    return pointsForMember.reduce((acc, points, playerB) => {
      if (playerB === player) {
        return acc;
      }
      const matchUp = this.getMatchUp(player, playerB);
      const pointsAgainstPlayerB = this.getPoints(matchUp.round, matchUp.game, matchUp.inverse) || 0;
      return acc + (pointsAgainstPlayerB * points);
    }, 0);
  }

  private makeDataforResultTable(): string {
    return this.resultsTable.reduce((acc, row, index) => {
      return acc + row.reduce((acc2, row2, index2) => {
        const res2 = acc2 + row2.map(
          row3 => {
            return {
              member: members.find(member => member.id === this.tournamentData.members[row3]),
              memberIndex: row3,
            };
          }
        ).reduce((acc3, mappedRow3) => {
          const name  = this.getNameForMember(mappedRow3.member);
          return acc3 + name
            + '|' + this.pointsAfterRound(index, mappedRow3.memberIndex, this.resultsTable) + '|';
        }, '') + `<strong>${this.getResult(index, index2)}</strong>#`;
        return res2;
      }, `<strong>Runde ${index + 1} - ${this.tournamentData.dates[index]}</strong>|#`) ;
    }, 'Teilnehmer|Punkte|Teilnehmer|Punkte|Ergebnis#');
  }

  private makeDataforStandingsTable(): string {
    const resultCountsForMember: ResultCount[] = [];
    const pointsForMember = this.tournamentData.members.map((memberId, index) => {
      const resultCount: ResultCount = {
        wins: 0,
        draws: 0,
        losses: 0,
        total: 0
      };
      const points = this.pointsAfterRound(this.tournamentData.dates.length, index, this.resultsTable, resultCount);
      resultCountsForMember.push(resultCount);
      return points;
    });
    const standings = this.tournamentData.members.map((memberId, index) => {
      return {
        nr: index + 1,
        name: this.getNameForIndex(index),
        points: pointsForMember[index],
        wins: resultCountsForMember[index].wins,
        draws: resultCountsForMember[index].draws,
        losses: resultCountsForMember[index].losses,
        total: resultCountsForMember[index].total,
        sober: this.getSoBerForPlayer(index, pointsForMember),
      };
    });
    standings.sort((a, b) => (b.points * 100 + b.sober) - (a.points * 100 + a.sober));
    return 'Pl.|Nr.|Teilnehmer|Spiele|G|R|V|Punkte|SoBer#' +
      standings.map( (standing, index) => `${index + 1}.|${standing.nr}|${standing.name}|${standing.total}|${standing.wins}|${standing.draws}|${standing.losses}|<strong>${standing.points}</strong>|${standing.sober}`).join('#');
  }

  private getNameForIndex(index: number): string {
    const member = members.find(member2 => member2.id === this.tournamentData.members[index]);
    return this.getNameForMember(member);
  }

  private getNameForMember(member: Member): string {
    return member.name ? `${member.name}, ${member.firstName}` : '';
  }

  private makeDataforCrossTable(): string {
    const cross: string [][] = [];
    this.tournamentData.members.forEach((memberId, playerA) => {
      cross[playerA] = [];
      this.tournamentData.members.forEach((memberId2, playerB) => {
        if (playerA !== playerB) {
          const matchUp = this.getMatchUp(playerA, playerB);
          const pointsAgainstPlayerB = this.getPoints(matchUp.round, matchUp.game, matchUp.inverse);
          if (pointsAgainstPlayerB !== undefined) {
            switch (pointsAgainstPlayerB) {
              case 1: cross[playerA][playerB] = '1'; break;
              case 0.5: cross[playerA][playerB] = '½'; break;
              case 0: cross[playerA][playerB] = '0'; break;
            }
          } else {
            cross[playerA][playerB] = '-';
          }
        } else {
          cross[playerA][playerB] = 'xx';
        }
      });
    });
    const header = 'Nr.|Teilnehmer|' + this.tournamentData.members.map((memberId, index) => `${index + 1}`).join('|') + '|Punkte#';
    const ret = header + cross.map((row, index) => `${index + 1}|${this.getNameForIndex(index)}|${row.join('|')}|<strong>${this.pointsAfterRound(this.tournamentData.results.length, index, this.resultsTable)}</strong>`).join('#');
    return ret;
  }

  private mapTable(table: number[][][]): number[][][] {
    if (this.tournamentData.roundMap) {
      return this.tournamentData.roundMap.map(round => table[round]);
    }
    return table;
  }

  private getPairingsTable(): number[][][] {
    if (this.tournamentData.pairings && PAIRINGS_CONFIG[this.tournamentData.pairings]) {
      return PAIRINGS_CONFIG[this.tournamentData.pairings];
    } else {
      switch (this.tournamentData.members.length)  {
        case 5:
        case 6:
          return PAIRINGS_CONFIG.PAIRINGS_6;
        case 7:
        case 8:
          return PAIRINGS_CONFIG.PAIRINGS_8;
      }
    }
  }

  ngOnInit(): void {
    this.resultsTable = this.mapTable(this.getPairingsTable());
    switch (this.tournamentTable.dynamicTableType) {
      case DynamicTableType.Pairings: this.data = this.makeDataforResultTable(); break;
      case DynamicTableType.Standings: this.data = this.makeDataforStandingsTable(); break;
      case DynamicTableType.CrossTable: this.data = this.makeDataforCrossTable(); break;
    }
  }
}
